<section *ngIf="!loading">
  <div class="card p-0 border-0 m-0">
    <div class="card-body p-0 mb-2">
        <!--
      <div class="alert alert-danger alert-dismissible fade show" role="alert">
        <a (click)="confirmed = true" data-bs-dismiss="alert" style="cursor: pointer;">
          cliquez ici pour confirmer que vous avez le montant total dans votre compte.
        </a>
      </div>
        -->
      <p class="text-center">
        <img src="./assets/img/OrangeMoney.png" alt="" style="max-height: 100px" class="img-fluid rounded">
      </p>
      <h5 class="text-center text-muted">Payer avec Orange Money</h5>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="form-group">
      <label>
        <h5 class="text-muted">Telephone <span class="required">*</span></h5>
      </label>
      <input type="tel" class="form-control" placeholder="Votre numéro de téléphone" [(ngModel)]="phone">
    </div>
  </div>

  <div class="form-group d-flex justify-content-between">
    <div class="text-center col pr-2">
      <a (click)="cancelPayment()" href="javascript:void(0);" class="default-btn bg-dark">Annuler</a>
    </div>
    <div class="col">
      <a (click)="submit()" *ngIf="!redirect" href="javascript:void(0);" class="default-btn">Verifier</a>
        <a (click)="closeModal()" *ngIf="redirect" [href]="paymentUrl" appExternalUrl class="default-btn">Payer</a>
    </div>

  </div>
</section>

<div class="row justify-content-center pt-70 pb-70" *ngIf="loading">
  <p class="text-center">
    <ngx-loading-spinner mini></ngx-loading-spinner>
  </p>
  <p class="text-center">traitement...</p>
</div>
