import { ConfirmUbaPaymentComponent } from './confirm-uba-payment/confirm-uba-payment.component';
import {InjectionToken, NgModule} from '@angular/core';
import {Routes, RouterModule, ActivatedRouteSnapshot} from '@angular/router';
import { ActivateAccountComponent } from './auth/activate-account/activate-account.component';
import { GuardGuard } from './core/guard/guard.guard';
import { InnerGuard } from './core/guard/inner.guard';
import { CommandComponent } from './feature/command/command.component';
import { TestUbaComponent } from './feature/web/pages/test-uba/test-uba.component';
import {OrangeMoneyComponent} from './feature/command/checkout/payment/orange-money/orange-money.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const deactivateGuard = new InjectionToken('deactivateGuard');

const routes: Routes = [
    {
        path: 'test-uba',
        component: TestUbaComponent
    },
    { path: 'activate-account/:token', component: ActivateAccountComponent, canActivate: [GuardGuard] },
    { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
    { path: 'register', redirectTo: 'auth/register', pathMatch: 'full' },
    { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), canActivate: [GuardGuard] },
    { path: '', loadChildren: () => import('./feature/web/web.module').then(m => m.WebModule) },
    { path: 'account', loadChildren: () => import('./feature/user/user.module').then(m => m.UserModule), canActivate: [InnerGuard] },
    {
        path: 'commands',
        component: CommandComponent,
        loadChildren: () =>
            import('./feature/command/command.module').then(m => m.CommandModule) },
    { path: 'uba/confirmPayment', component: ConfirmUbaPaymentComponent },
    {
        path: 'externalRedirect',
        canActivate: [externalUrlProvider],
        component: OrangeMoneyComponent,
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [
        {
            provide: externalUrlProvider,
            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = route.paramMap.get('externalUrl');
                // Fix for Orange money
                const finalUrl = 'https://mpayment' + externalUrl.split('https://mpayment')[1];
                window.open(finalUrl, '_blank');
            },
        },
        {
            provide: deactivateGuard,
            useValue: () => {
                console.log('Guard function is called!');
                return false;
            }
        },
    ],
})
export class AppRoutingModule { }
