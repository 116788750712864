import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { CheckoutService } from '../../checkout/service/checkout.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-orange-money',
  templateUrl: './orange-money.component.html',
  styleUrls: ['./orange-money.component.scss']
})
export class OrangeMoneyComponent implements OnInit {
  @Output() newEvent = new EventEmitter<string>();
  @Input() command: any;
  phone: any;
  loading = true;
  // confirmed = true;
    confirmed = true;
  paymentUrl: any;
  redirect = false;

  constructor(
      private checkoutService: CheckoutService,
      public modal: NgbModal,
      private sanitizer: DomSanitizer,
      private toast: ToastrService ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  cancelPayment() {
    this.newEvent.emit('');
  }

  submit() {
    this.loading = true;
    if (this.checkPhoneIfCorrects(this.phone)) {
      Swal.fire({
        titleText: 'Numéro correct !',
        text: 'Veuillez patienter',
        icon: 'info',
        backdrop: false,
        showConfirmButton: false,
        showDenyButton: false,
      });
      const idcmd: number = Number(this.command.idcommande);
      const tel: number = Number(this.phone);
      this.checkoutService.payWithMomoOrOM(idcmd, tel).subscribe(
          (res: string) => {
          Swal.close();
          // this.modal.dismissAll({ pay: true });
          this.loading = false;
          this.toast.info('Votre demande de paiement a été enclenchée', 'Info');
          this.toast.info('Bien vouloir valider la transaction sur votre téléphone', 'Info');
          this.paymentUrl = this.sanitizer.bypassSecurityTrustUrl(res.slice(0, -1));
          this.redirect = true;
        }, (error: HttpErrorResponse) => {
          this.toast.error('Une erreur est survenue !!', 'Erreur');
          this.toast.error(`${error.message}`, `${error.status}`);
          this.loading = false;
          Swal.close();
        }, () => {
          }
      );
    } else {
      this.loading = false;
    }
  }

    closeModal() {
        this.modal.dismissAll({ pay: true });
    }


  checkPhoneIfCorrects(value: string): boolean {
    if (!isNaN(Number(value)) && value.length === 9) {
      if (!value.startsWith('6')) {
        Swal.fire({
          titleText: 'Attention',
          text: 'Veuillez saisir un numero de ORANGE Cameroun',
          icon: 'error',
          confirmButtonColor: '#0F429E'
        }).then(() => {
          return false;
        });
      } else {
        const sVal = value.substring(0, 3);
        if (sVal.charAt(1).toString() ===
          '9') {
          return true;
        } else if (sVal.charAt(1)
          .toString() ===
          '5') {
          if (parseInt(sVal.charAt(2)
            .toString(), 10) >
            4) {
            return true;
          } else {
            Swal.fire({
              titleText: 'Attention',
              text: 'Veuillez saisir un numero de ORANGE Cameroun',
              icon: 'error',
              confirmButtonColor: '#0F429E'
            }).then(() => {
              return false;
            });
          }
        } else {
          Swal.fire({
            titleText: 'Attention',
            text: 'Veuillez saisir un numero de ORANGE Cameroun',
            icon: 'error',
            confirmButtonColor: '#0F429E'
          }).then(() => {
            return false;
          });
        }
      }
    } else {
      Swal.fire({
        titleText: 'Attention',
        text: 'Veuillez saisir un numero de telephone à 9 chiffres et recomencer !',
        icon: 'warning',
        confirmButtonColor: '#0F429E'
      });
    }
  }

}
